@use "sass/forward" as *;

// fuentes
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600;800&display=swap");

html {
  background: url("img/arena-patron.jpg");
  font-size: clamp(1.1rem, 0.8rem, 4vw);
  scroll-behavior: smooth;
}

body {
  font-family: Montserrat;
  font-weight: 300;
  position: relative;
  color: $azulOscuro;
}
h1,
h2,
h3,
h4 {
  font-family: Montserrat;
  font-weight: 600;
  b {
    font-weight: 600;
  }
}
figure {
  margin: 0;
  padding: 0;
}
#cuerpo {
  overflow: hidden;
}

h1 {
  font-size: clamp(1.5rem, 2.2vw, 3rem);
}

.minmax {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
  // grid-template-columns: repeat(8, 1fr);
  margin-bottom: 9rem;
  @include cel {
    margin-bottom: 0rem;
  }
}

.conte {
  max-width: 1920px;
  margin: 0 auto;
  padding: 0 5vw;
}
#nav {
  position: absolute;
  left: 0px;
  top: -2rem;
  @include cel {
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
  }

  a {
    color: $azulOscuro;
    font-weight: 600;
  }
}
#hero {
  display: flex;
  gap: 2em;

  .izq {
    flex: 1 1 50%;
    display: inherit;
    justify-content: center;
    align-items: center;
    padding-top: 3rem;
    .bandeja {
      position: relative;
      width: 60%;
      .logo {
        width: 40%;
        height: auto;
      }
      h1 {
        padding: 1.5rem 0;
      }
    }
  }
  .der {
    flex: 1 1 50%;
    position: relative;
    img {
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      width: 100%;
      height: auto;
      object-fit: cover;
    }
    &:after {
      content: "";
      position: absolute;
      height: 5em;
      width: 100%;
      bottom: -3%;
      left: 0;
      background-color: $azulOscuro;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      z-index: -1;
    }
  }
  @include cel {
    flex-wrap: wrap;
    .izq {
      flex: 1 1 100%;
      order: 2;
      text-align: center;
      .logo {
        width: 100% !important;
        img {
          width: 60%;
          height: auto;
        }
      }
    }
    .der {
      order: 1;
      flex: 1 1 100%;
      height: 60vh;
      img {
        height: 100%;
      }
    }
  }
}
#reservaFixed {
  display: none;
  @include cel {
    display: block;
  }
  position: fixed;
  bottom: 0;
  left: 0%;
  width: 100%;
  z-index: 900;
  background-color: #b8d7e1;
  height: 50px;
  border-top-left-radius: 1.5rem;
  border-top-right-radius: 1.5rem;
  a {
    position: relative;
    left: 50%;
    transform: translate(-50%, -50px);
  }
}
.escritorio {
  @include cel {
    display: none !important;
    border: solid;
  }
}
#contexto {
  padding: 6rem 3.5rem;
  @include cel {
    padding: 10%;
    text-align: center;
  }
  padding: 6rem 3.5rem;
  @include hd {
    padding: 8rem 15%;
  }
  h2 {
    padding-bottom: 2rem;
    @include cel {
      font-size: 1.2rem;
    }
  }
}
#contextoB {
  transform: translateY(2rem);
  -webkit-transform: translateY(2rem);
  margin-top: 3em;
  background: url("img/Fondo-arena.jpg") no-repeat;
  background-position: top right;
  background-size: contain;
  @include hd {
    background-size: 60%;
  }
  display: flex;
  flex-wrap: wrap;
  p {
    font-size: clamp(0.9rem, 1.3vw, 1.7em);
  }
  .izq {
    figure {
      transform: translateY(-2rem);
      -webkit-transform: translateY(-2rem);
      img {
        border-bottom-right-radius: 5rem;
        width: 100%;
        height: auto;
        object-fit: cover;
        @include cel {
          height: 55vh;
        }
      }
    }
    flex: 1 0 50%;

    display: flex;
    justify-content: center;
    align-items: center;
    p {
      width: 70%;
    }
    p:last-child{
      padding-bottom: 3rem;
    }
  }
  .der {
    flex: 1 0 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      width: 70%;
    }

    figure {
      img {
        border-bottom-left-radius: 5rem;
        width: 100%;
        height: auto;
        object-fit: cover;
        @include cel {
          height: 55vh;
        }
      }
    }
  }
}
#amenidades {
  background: url("img/fondo-azul.jpg") $azulOscuro no-repeat;
  background-size: contain;
  background-position: top center;
  padding-top: 4rem;
  p {
    color: #fff;
    padding: 3rem 0 4rem;
  }
  img {
    border-top-left-radius: 4rem;
    margin-bottom: -6px;
    @include cel {
      height: 30vh;
      width: auto;
      object-fit: cover;
    }
  }
  .bandeja {
    background-color: $beige;
    margin: 0;
    padding: 25vh 15%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    h2 {
      padding-bottom: 2rem;
      flex: 1 0 70%;
      display: block-inline;
    }
    ul {
      list-style: disc;
      columns: 2;
      column-gap: 2rem;
      li {
        color: $azulOscuro;
      }
    }
    @include hd {
      padding: 10vh 15%;
  
    }
    @include cel {
      padding: 8vh 3rem;
      ul {
        columns: 1;
      }
      li{
        padding-top: 0.8rem;
      }
    }
  }
  .conte {
    width: 80%;
    @include hd {
      width: 50%;
    }
    // margin:clamp(3rem, 1.5vw, 3.5rem);
  }
}

#amenidades-2 {
  background-color: $azul;

  img {
    border-bottom-right-radius: 4rem;
    margin-bottom: -6px;
    @include cel {
      height: 30vh;
      width: auto;
      object-fit: cover;
    }
  }
  .bandeja {
    margin: 0;
    padding: 15vh 0;
    @include hd {
      padding: 10vh 0;
    }
    display: flex;
    justify-content: center;
    align-items: center;
    ul {
      list-style: disc;
      columns: 2;
      width: 70%;
      column-gap: 2rem;
      li {
        color: #fff;
      }
    }

    @include cel {
      padding: 8vh 0;
      ul {
        columns: 1;
      }
      li{
        padding-top: 0.8rem;
      }
    }
  }
  .conte {
    width: 80%;
    @include hd {
      width: 50%;
    }
    // margin:clamp(3rem, 1.5vw, 3.5rem);
  }
}
#habitaciones {
  position: relative;
  .conte {
    margin: 6rem 0 4rem;
  }
}
#slider:hover {
  &::-webkit-scrollbar-thumb {
    visibility: visible;
  }
}

#slider {
  display: flex;
  gap: 1rem;
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 5em;
  @include hd {
    padding-bottom: 5%;
  }
  width: 100%;
  overflow-x: auto;
  overflow-y: visible;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  scroll-snap-points-x: repeat(400px);
  scroll-snap-type: mandatory;
  &::-webkit-scrollbar {
    width: 10px;
    height: 15px;
  }
  &::-webkit-scrollbar-thumb {
    background: $azul;
    border-radius: 10px;
    width: 50px;
    visibility: hidden;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  .habitacion {
    flex: 1 0 30%;
    @include hd {
      flex: 1 0 25%;
    }
    @include cel {
      flex: 1 0 90%;
    }
    background-color: $beige;
    padding: 1rem;
    border-radius: 2rem;
    position: relative;
    img {
      border-top-left-radius: 1rem;
      border-top-right-radius: 1rem;
      width: 100%;
      height: auto;
    }
    h2 {
      display: block;
      background-color: $azulOscuro;
      color: #fff;
      padding: 1rem;
      border-bottom-left-radius: 1rem;
      border-bottom-right-radius: 1rem;
      margin-top: -5px;
      font-size: clamp(1.2rem, 1.8vw, 2rem);
    }
    p {
      margin: 2.5rem 1rem;
    }
    a {
      position: absolute;
      bottom: -10%;
      left: 50%;
      transform: translateX(-50%);
      -webkit-transform: translateX(-50%);
      @include cel {
        bottom: -7%;
      }
    }
  }
}
#todas {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  h2 {
    flex: 1 0 100%;
    padding-bottom: 2rem;
  }
  ul {
    list-style: disc;
    columns: 2;
    width: 70%;
    column-gap: 2rem;
    @include cel {
      columns: 1;
    }
    li {
      color: $azulOscuro;
    }
  }
}
#elh2 {
  background-color: $azulOscuro;
  color: #fff;
  padding: 3rem 3rem 5rem;
  border-top-right-radius: 2rem;
}
#mapa {
  height: 55vh;
  border-top-right-radius: 2rem;
  margin-top: -2rem;
}
.iconoscontacto {
  position: relative;
  margin-top: -2rem;
  z-index: 400;
  border-top-right-radius: 2rem;
  border-bottom-left-radius: 2rem;
  background: url("img/fondo-azul.jpg") no-repeat $azulOscuro;
  background-size: cover;
  color: #fff;
  display: flex;
  @include cel {
    flex-wrap: wrap;
    height: auto;
    padding: 3rem 0;
  }
  justify-content: center;
  // min-height: 40vh;
  padding: 5rem 4rem;
  h3{
    padding-bottom: 1rem;
  }
  a {
    color: #fff;
  }
  li {
    list-style: none;
  }
  .item {
    text-align: center;
    flex: 1 0 33%;
    img {
      width: 2.7rem;
      padding-bottom: 1rem;
    }
    @include cel {
      padding: 1.5rem 3rem;
      img {
        width: 2.4rem;
        padding-bottom: 1rem;
      }
    }
    li {
      display: inline-block;
      padding: 0.5rem 0.5rem 0;
    }
  }
}

#footer {
  margin-top: -3rem;
  // background: url('img/Fondo-arena.jpg') no-repeat $beige;
  background: $beige;
  background-position: top right;
  background-size: contain;
  background-blend-mode: multiply;
  .contePie {
    padding: 8rem 5rem 5rem;
    text-align: center;
    figure {
      img {
        width: 10%;
        height: auto;
        @include cel {
          width: 70%;
        }
      }
    }
  }
  ul {
    li {
      display: inline-block;
      padding: 2rem;
    }
    @include cel {
      padding-bottom: 2rem;
      li {
        padding: 0.6rem;
      }
    }
  }
}
#oscuro {
  background: rgba(26, 50, 96, 0.9);
  width: 100%;
  height: 100vw;
  position: fixed;
  top: 0%;
  left: 0%;
  z-index: 99999;
}
.revelate {
  display: block;
}
.escondete {
  display: none;
}

#sistema {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  overflow: scroll;
  width: 40%;
  height: 100vh;
  background-color: #fff;
  @include cel {
    width: 100%;
    min-height: 100vh;
  }
  figure {
    img {
      width: 100%;
      height: 40vh;
      object-fit: cover;
      border-bottom-left-radius: 2rem;
      border-bottom-right-radius: 2rem;
      transform: translateY(-20vh);
      -webkit-transform: translateY(-20px);
    }
  }
  p {
    font-size: 0.8em;
    color: $azulOscuro;
    padding: 2rem;
    text-align: center;
  }
}
#encabezadoSistema {
  position: relative;
  background-color: $azulOscuro;
  color: #fff;
  display: flex;
  padding: 1.2rem;
  border-bottom-right-radius: 2rem;
  z-index: 500;
  h2 {
    padding-left: 1rem;
    font-size: 1.2rem;
  }
}
#datos {
  position: relative;
  background-color: $beige;
  border-radius: 2rem;
  padding: 2rem;
  width: 90%;
  margin: 0 auto;
  transform: translateY(-10vh);
  -webkit-transform: translateY(-10vh);
  a {
    position: absolute;
    bottom: -70px;
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    width: 70%;
    text-align: center;
  }
}
input {
  width: 100%;
  border: none;
  padding: 0.4rem;
  font-size: 1rem;
  color: $azulOscuro;
  margin: 0.5rem 0 1rem;
  background-color: #fff;
  border-radius: 5px;
}
label {
  font-weight: bold;
  color: $azul;
  img {
    vertical-align: middle;
    width: 25px;
    height: auto;
  }

  span {
    font-size: 0.8em;
    font-weight: normal;
  }
}
.desactivado {
  opacity: 0.2;
  pointer-events: none;
}
.activate {
  opacity: 1;
  pointer-events: auto;
}
.miniemail{
  @include cel{
    font-size: 0.9rem;
  }
}